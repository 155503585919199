
import { getISOWeek, getISOWeekYear, parseISO, format, add, isAfter, getYear, getWeek } from 'date-fns';
import { nl } from 'date-fns/locale';

export const detectMobile = () => {
  // Adjusted alert to properly display the user agent string
  // alert(`User Agent: ${navigator.userAgent}` + '-------' + navigator.maxTouchPoints);

  const ua = navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(ua);
  const isAndroidOrOtherMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(ua);

  // Adding a check for iPads using iPadOS 13 and later
  const isIPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

  // console.log('Mobile device:', isIOS || isAndroidOrOtherMobile || isIPadOS);

  return isIOS || isAndroidOrOtherMobile || isIPadOS;
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const capitalizeFirstLetter = (string) => {
  if(!string) {
    return string;
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const statusToString = (status) => {
  switch (status) {
      case 1:
          return "Geen boekingen";
      case 2:
          return "1 Flex boeking: 1 plek vrij";
      case 3:
          return "Duo boeking: vol";
      case 4:
          return "Solo boeking: vol";
      case 5:
          return "2 Flex boekingen: vol";
      case 6:
          return "1 Flex & 1 Solo boeking: vol";
      case 7:
          return "KMT: leeg";
      case 8:
          return "KMT: vol";
      case 99:
        return "Google Agenda";
      default:
          return "Onbekende status";  // Default case if an unexpected status is provided
  }
}

export const isAtLeast1HourAway = (dateString) => {
  const targetDateLocal = new Date(dateString);
  const targetDateUtc = new Date(Date.UTC(targetDateLocal.getFullYear(), targetDateLocal.getMonth(), targetDateLocal.getDate(), targetDateLocal.getHours(), targetDateLocal.getMinutes(), targetDateLocal.getSeconds()));
  const now = new Date();
  const oneDayFromNow = add(now, { hours: 1 });

  console.log('------------');
  console.log('Target Date (UTC):', targetDateUtc);
  console.log('One Day From Now:', oneDayFromNow);
  console.log('------------');
  const result = isAfter(targetDateUtc, oneDayFromNow);
  console.log('Is at least 1 hours from now:', result);
  return result;
}
export const isAtLeast6HoursAway = (dateString) => {
  const targetDateLocal = new Date(dateString);
  const targetDateUtc = new Date(Date.UTC(targetDateLocal.getFullYear(), targetDateLocal.getMonth(), targetDateLocal.getDate(), targetDateLocal.getHours(), targetDateLocal.getMinutes(), targetDateLocal.getSeconds()));
  const now = new Date();
  const oneDayFromNow = add(now, { hours: 6 });

  console.log('------------');
  console.log('Target Date (UTC):', targetDateUtc);
  console.log('One Day From Now:', oneDayFromNow);
  console.log('------------');
  const result = isAfter(targetDateUtc, oneDayFromNow);
  console.log('Is at least 1 hours from now:', result);
  return result;
}

export const isAtLeast24HoursAway = (dateString) => {
  const targetDateLocal = new Date(dateString);
  const targetDateUtc = new Date(Date.UTC(targetDateLocal.getFullYear(), targetDateLocal.getMonth(), targetDateLocal.getDate(), targetDateLocal.getHours(), targetDateLocal.getMinutes(), targetDateLocal.getSeconds()));
  const now = new Date();
  const oneDayFromNow = add(now, { days: 1 });

  console.log('------------');
  console.log('Target Date (UTC):', targetDateUtc);
  console.log('One Day From Now:', oneDayFromNow);
  console.log('------------');
  const result = isAfter(targetDateUtc, oneDayFromNow);
  console.log('Is at least 1 hours from now:', result);
  return result;
}


export const isAtLeast24HoursAwayFormatted = (dateString) => {
  const targetDate = parseISO(dateString);
  const now = new Date();
  const oneDayFromNow = add(now, { days: 1 });
  // console.log('------------');
  // console.log(targetDate);
  // console.log(now);
  // console.log(oneDayFromNow);
  // console.log('------------');
  // console.log(isAfter(targetDate, oneDayFromNow));
  return isAfter(targetDate, oneDayFromNow);
}


export const isDateInRange = (date, startDate, endDate) => {
  const dateToCheck = new Date(date);
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const dateToCheckTime = dateToCheck.getTime();
  const startDateObjTime = startDateObj.getTime();
  const endDateObjTime = endDateObj.getTime();

  return dateToCheckTime >= startDateObjTime && dateToCheckTime <= endDateObjTime;
}

export const findDifferences = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const diffKeys = [];

  // Check for keys in obj1 that have different values in obj2
  for (let key of keys1) {
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (!arraysOfObjectsEqual(obj1[key], obj2[key])) {
        diffKeys.push(key);
      }
    } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      const nestedDiffs = findDifferences(obj1[key], obj2[key]);
      if (nestedDiffs.length > 0) {
        diffKeys.push(key);
      }
    } else if (obj1[key] !== obj2[key]) {
      diffKeys.push(key);
    }
  }

  // Check for keys in obj2 that are not present in obj1
  for (let key of keys2) {
    if (!obj1.hasOwnProperty(key)) {
      diffKeys.push(key);
    }
  }

  return diffKeys;
}

function arraysOfObjectsEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  
  // sort array by JSON string representation to ensure order is the same
  const sortedArr1 = arr1.map(JSON.stringify).sort();
  const sortedArr2 = arr2.map(JSON.stringify).sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }
  return true;
}

export const getUpcomingWeekNumbers = (numWeeks) => {
  const now = new Date();
  let currentWeek = getISOWeek(now);
  let currentYear = getISOWeekYear(now);

  let upcomingWeeks = [];
  for(let i = 0; i < numWeeks; i++) {
      let week = currentWeek + i;
      let year = currentYear;
      
      // Handle week rollover
      if (week > 52) {
          week = week - 52;
          year++;
      }
      upcomingWeeks.push({ week: week, year: year });
  }

  return upcomingWeeks;
};

export const getFirstDayOfWeek = (year, week) => {
  let date = new Date(Date.UTC(year, 0, (week - 1) * 7 + 1));
  let day = date.getUTCDay();

  if (day <= 4) // Monday, Tuesday, Wednesday, Thursday
      date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 1);
  else // Friday, Saturday, Sunday
      date.setUTCDate(date.getUTCDate() + 8 - date.getUTCDay());

  return date;
}
export const getLastDayOfWeek = (year, week) => {
  let date = new Date(Date.UTC(year, 0, (week - 1) * 7 + 1));
  let day = date.getUTCDay();

  if (day <= 4) // Monday, Tuesday, Wednesday, Thursday
      date.setUTCDate(date.getUTCDate() - day + 7);
  else // Friday, Saturday, Sunday
      date.setUTCDate(date.getUTCDate() + 14 - day);

  return date;
}

// export const formattedRecurringDate = (event) => {
//   const dateString = event.startStr || event.start;
//   const date = parseISO(dateString);
//   return format(date, "eeee 'om' HH:mm", { locale: nl });
// }
export const formattedRecurringDate = (event) => {
  const dateString = event.startStr || event.start;

  // Extract date and time components from the string
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
  const [hours, minutes] = timePart.split(':').map(num => parseInt(num, 10));

  // Construct a formatted string
  const dayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];

  const dateObj = new Date(Date.UTC(year, month - 1, day, hours, minutes));
  const dayName = dayNames[dateObj.getUTCDay()];
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

  return `${dayName} om ${formattedTime}`;
}


export const getCurrentWeek = (date) => {
  return getISOWeek(date);
}
export const getCurrentYear = (date) => {
  return date.getFullYear();
}
export const getNextYear = () => {
  return new Date().getFullYear() + 1;
}

export const getYearFromDate = (dateString) => {
  const date = parseISO(dateString);
  return getYear(date);
}

export const getWeekFromDate = (dateString) => {
  const date = parseISO(dateString);
  return getISOWeek(date).toString();
}